@font-face {
    font-family: 'Lucida Handwriting';
    font-style: normal;
    font-weight: normal;
    src: url('./files/LucidaHandwriting.TTF') format('truetype');
}

@font-face {
    font-family: 'Garamond';
    font-style: normal;
    font-weight: normal;
    src: url('./files/Garamond.woff') format('woff');
}

@font-face {
    font-family: 'Lucida Console';
    font-style: normal;
    font-weight: normal;
    src: url('./files/LucidaConsole.ttf') format('truetype');
}

@font-face {
    font-family: 'Catamaran';
    font-style: normal;
    font-weight: normal;
    src: url(./files/Catamaran.ttf) format('truetype');
}

@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: normal;
    src: url(./files/Oswald.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    src: url(./files/Montserrat.ttf) format('truetype');
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    src: url(./files/OpenSans.ttf) format('truetype');
  }
  
  